import React from 'react';
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

export function ColorModeSwitcher ({ size, top, right }) {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('Dark', 'Light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const colorMode = useColorMode().colorMode

  return (
    <IconButton
      aria-label={`Switch to ${text.toLowerCase()} mode`}
      onClick={toggleColorMode}
      position='fixed'
      top={top}
      right={right}
      size={size}
      icon={<SwitchIcon color={colorMode === 'dark' ? '#B8B9BF' : '#4E5058'} />}
    />
  );
};
