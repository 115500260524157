import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'system',
  useSystemColorMode: true,
}

const styles = {
  global: props => ({
    body: {
      bg: mode('white', 'rgb(0, 32, 44)')(props),
    },
  }),
};

const theme = extendTheme({ config, styles })

export default theme