import { useState } from 'react';
import theme from './theme';
import {
  ChakraProvider,
  Stack,
  VStack,
  HStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Heading,
  Flex,
  Input,
  Box
} from '@chakra-ui/react'

import { MdContentCopy, MdCheck } from 'react-icons/md'

import { ColorModeSwitcher } from './ColorModeSwitcher';

function calculate_c_analyte({ v_analyte, c_titrant, v_tritant, ratio }) {
  const n_tritant = v_tritant * c_titrant
  const n_analyte = ratio * n_tritant
  const c_analyte = n_analyte / v_analyte
  return c_analyte
}

function App() {
  const [v_analyte, set_v_analyte] = useState(14) // mL
  const [c_tritant, set_c_tritant] = useState(0.1) // mol/L
  const [v_tritant, set_v_tritant] = useState(7) // mL
  const [ratioLeft, setRatioLeft] = useState(1) // Coefficient Analyte (From neutralisation formula)
  const [ratioRight, setRatioRight] = useState(1) // Coefficient Tritant (From neutralisation formula)

  const c_analyte = calculate_c_analyte({ v_analyte: v_analyte, c_titrant: c_tritant, v_tritant: v_tritant, ratio: ratioLeft / ratioRight })

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  window.addEventListener('resize', () => setScreenWidth(window.innerWidth))

  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  window.addEventListener('resize', () => setScreenHeight(window.innerHeight))

  const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); //eslint-disable-line

  return (
    <ChakraProvider theme={theme}>
        <ColorModeSwitcher top={screenWidth < 300 ? 2 : (screenWidth < 600 ? 3 : 4)} right={screenWidth < 300 ? 2 : (screenWidth < 600 ? 3 : 4)} size={screenWidth < 300 ? 'xs' : (screenWidth < 600 ? 'sm' : 'md')}/>
        <VStack alignItems='center' justifyContent={screenWidth < 420 ? 'top' : 'center'} minHeight={screenWidth < 420 ? 'unset' : '100vh'} spacing={4} py={10}>
          <Heading fontSize={screenWidth < 600 ? '2xl' : '4xl'} mb={4}>Titration Calculator</Heading>
          <VStack align={screenWidth < 600 ? 'center' : 'left' }  spacing={4}>
            <Stack direction={screenWidth < 600 ? 'column' : 'row'} width={screenWidth < 600 ? 250 : 'auto'} spacing={4}>
              <VStack align='left'>
                <Flex justifyContent='space-between' alignItems='center'>
                  <Heading fontSize={18}>Volume Analyte</Heading>
                  <Text fontSize={18}>mL</Text>
                </Flex>
                <NumberInput size='lg' allowMouseWheel defaultValue={v_analyte} min={0} onChange={set_v_analyte}>
                  <NumberInputField  _focusVisible={{ ring: '1px', ringColor: '#00c8ff', ringOffset: '0px', borderColor: '#00c8ff' }}/>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </VStack>
              <VStack align='left'>
                <Flex justifyContent='space-between' alignItems='center'>
                  <Heading fontSize={18}>Concentration Analyte</Heading>
                  <Text fontSize={18}>mol/L</Text>
                </Flex>
                <Flex id='c_analyte' fontWeight='normal' transition='background-color 0.3s' size='lg' userSelect='none' alignItems='center' border='1px solid #00c8ff' height={47.5} width={isSafari && !(screenWidth < 600) ? 269 : 250} paddingX={3} paddingY={2} borderRadius={6} bg={`#00c8ff${c_analyte > 0 && c_analyte < Infinity ? '20' : '00'}`} onClick={() => {
                      if (!c_analyte > 0) return
                      navigator.clipboard.writeText(c_analyte)
                      const valueElement = document.getElementById('c_analyte')
                      valueElement.style.boxShadow = '0px 0px 0px 1px #00c8ff'
                      valueElement.style.background = `#00c8ff${c_analyte > 0 && c_analyte < Infinity ? '40' : '00'}`
                      const displayValueElement = document.getElementById('c_analyte_display')
                      // displayValueElement.style.opacity = 0
                      displayValueElement.style.display = 'none'
                      const copyButton = document.getElementById('c_analyte_copy')
                      copyButton.style.display = 'none'
                      const copiedElement = document.getElementById('c_analyte_copied')
                      copiedElement.style.display = 'block'
                      const copiedCheckmark = document.getElementById('copied_checkmark')
                      copiedCheckmark.style.display = 'block'
                      setTimeout(() => {
                        valueElement.style.boxShadow = 'none'
                        valueElement.style.background = `#00c8ff${c_analyte > 0 && c_analyte < Infinity ? '20' : '00'}`
                        // displayValueElement.style.opacity = 1
                        displayValueElement.style.display = 'block'
                        copiedElement.style.display = 'none'
                        copyButton.style.display = 'block'
                        copiedCheckmark.style.display = 'none'
                      }, 750);
                  }}>
                  <Flex justifyContent='space-between' alignItems='center' width='100%'>
                    <Text id='c_analyte_display' transition='opacity 0.08s' fontSize={18}>{c_analyte > 0 && c_analyte < Infinity ? c_analyte : ''}</Text>
                    <Text id='c_analyte_copied' display='none'  fontSize={18}>Copied</Text>
                    <Box id='copied_checkmark' display='none'><MdCheck/></Box>
                    <MdContentCopy id='c_analyte_copy' opacity={c_analyte > 0 && c_analyte < Infinity ? 1 : 0}/>
                  </Flex>
                </Flex>
              </VStack>
            </Stack>
            <Stack direction={screenWidth < 600 ? 'column' : 'row'} width={screenWidth < 600 ? 250 : 'auto'} spacing={4}>
              <VStack align='left'>
                <Flex justifyContent='space-between' alignItems='center'>
                  <Heading fontSize={18}>Volume Titrant</Heading>
                  <Text fontSize={18}>mL</Text>
                </Flex>
                <NumberInput size='lg' allowMouseWheel defaultValue={v_tritant} min={0} onChange={set_v_tritant}>
                  <NumberInputField  _focusVisible={{ ring: '1px', ringColor: '#00c8ff', ringOffset: '0px', borderColor: '#00c8ff' }}/>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </VStack>
              <VStack align='left'>
                <Flex justifyContent='space-between' alignItems='center'>
                  <Heading fontSize={18}>Concentration Titrant</Heading>
                  <Text fontSize={18}>mol/L</Text>
                </Flex>
                <NumberInput size='lg' allowMouseWheel step={0.1} defaultValue={c_tritant} min={0} onChange={set_c_tritant}>
                  <NumberInputField  _focusVisible={{ ring: '1px', ringColor: '#00c8ff', ringOffset: '0px', borderColor: '#00c8ff' }}/>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </VStack>
            </Stack>
            <VStack align='left'>
              <Heading fontSize={18}>Ratio</Heading>
              <Flex justifyContent='space-between' flexDirection={screenWidth < 600 ? 'column' : 'row'}>
                <HStack mb={screenWidth < 600 ? 2 : 0}>
                  <Input size='lg' type='number' min={0} width='48px' textAlign='center' defaultValue={ratioLeft} onChange={e => setRatioLeft(e.target.value)} _focusVisible={{ ring: '1px', ringColor: '#00c8ff', ringOffset: '0px', borderColor: '#00c8ff' }}/>
                  <Text fontSize={18}>:</Text>
                  <Input size='lg' type='number' min={0} width='48px' textAlign='center' defaultValue={ratioRight} onChange={e => setRatioRight(e.target.value)} _focusVisible={{ ring: '1px', ringColor: '#00c8ff', ringOffset: '0px', borderColor: '#00c8ff' }}/>
                </HStack>
                <Text fontSize={screenWidth < 600 ? 12.7 : 18}>Coefficient Analyte : Coefficient Tritant<br />(From reactants in neutralisation equation)</Text>
              </Flex>
            </VStack>
          </VStack>
        </VStack>
        <Text position={screenWidth < 420 || screenHeight < 455 || (screenWidth < 600 && screenHeight < 671 ) ? 'block' : 'fixed'} width='100%' bottom={0} pb={4} fontSize='smaller' textAlign='center'>Created by Anthony Smart</Text>
    </ChakraProvider>
  );
}

export default App;